"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createWarehouseAreaRawMaterial = createWarehouseAreaRawMaterial;
exports.deleteWarehouseAreaRawMaterial = deleteWarehouseAreaRawMaterial;
exports.editWarehouseAreaRawMaterial = editWarehouseAreaRawMaterial;
exports.getWarehouseAreaRawMaterial = getWarehouseAreaRawMaterial;
exports.getWarehouseAreaRawMaterials = getWarehouseAreaRawMaterials;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getWarehouseAreaRawMaterials(params) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas-raw-materials',
    method: 'get',
    timeout: 12000,
    // request timeout
    params
  });
}
function getWarehouseAreaRawMaterial(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas-raw-materials/' + id,
    method: 'get'
  });
}
function createWarehouseAreaRawMaterial(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas-raw-materials',
    data,
    method: 'post'
  });
}
function editWarehouseAreaRawMaterial(data) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas-raw-materials/' + data.uuid,
    data,
    method: 'put'
  });
}
function deleteWarehouseAreaRawMaterial(id) {
  return (0, _request.default)({
    url: '/api/v1/warehouse-areas-raw-materials/' + id,
    method: 'delete'
  });
}