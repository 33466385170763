"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCategory = createCategory;
exports.deleteCategory = deleteCategory;
exports.editCategory = editCategory;
exports.getCategories = getCategories;
exports.getCategory = getCategory;
exports.pushCategory = pushCategory;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getCategories(params) {
  return (0, _request.default)({
    url: '/api/v1/categories',
    method: 'get',
    params
  });
}
function getCategory(id) {
  return (0, _request.default)({
    url: '/api/v1/categories/' + id,
    method: 'get'
  });
}
function createCategory(data) {
  return (0, _request.default)({
    url: '/api/v1/categories',
    data,
    method: 'post'
  });
}
function editCategory(data) {
  return (0, _request.default)({
    url: '/api/v1/categories/' + data.uuid,
    data,
    method: 'put'
  });
}
function deleteCategory(id) {
  return (0, _request.default)({
    url: '/api/v1/categories/' + id,
    method: 'delete'
  });
}
function pushCategory(id) {
  return (0, _request.default)({
    url: '/api/v1/categories/' + id + '/push',
    method: 'post'
  });
}