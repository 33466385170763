"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _pathToRegexp = _interopRequireDefault(require("path-to-regexp"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      levelList: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['permission_routes'])
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title);
      const first = matched[0]; // matched[matched.length - 1]

      matched = matched.filter(item => item.meta.breadcrumb !== false);
      const selectedBranchRoute = this.flattenRoute(this.getMatchedRoutes(matched[matched.length - 1].path, this.permission_routes));
      if (!this.isDashboard(first) && selectedBranchRoute.length < 2) {
        matched = [{
          path: '/dashboard',
          meta: {
            title: 'Dashboard'
          }
        }].concat(matched);
      } else {
        matched = selectedBranchRoute;
      }
      this.levelList = matched;
    },
    flattenRoute(route) {
      let routes = [route];
      if (Array.isArray(route.children) && route.children.length) {
        routes = routes.concat(this.flattenRoute(route.children[0]));
      }
      return routes;
    },
    getMatchedRoutes(path, routes = [], parentRoute = '') {
      let nonNested = routes.findIndex(route => route.path === path || parentRoute && route.path[0] !== '/' && (parentRoute + '/' + route.path === path || parentRoute + '/' + route.path === '/' + path));
      if (nonNested !== -1) {
        nonNested = {
          ...routes[nonNested],
          path: parentRoute && routes[nonNested].path[0] !== '/' ? parentRoute + (parentRoute !== '/' ? '/' : '') + routes[nonNested].path : routes[nonNested].path
        };
        delete nonNested.children;
        return nonNested;
      }

      // nested
      for (const route of routes) {
        if (Array.isArray(route.children)) {
          const parentPath = parentRoute && route.path[0] !== '/' ? parentRoute + (parentRoute !== '/' ? '/' : '') + route.path : route.path;
          const childrenSearch = this.getMatchedRoutes(path, route.children, parentPath);
          if (childrenSearch) {
            if (route.path === '/') {
              return {
                ...childrenSearch
              };
            } else if (childrenSearch) {
              return {
                ...route,
                path: parentPath,
                children: [childrenSearch]
              };
            }
          }
        }
      }
      return false;
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const {
        params
      } = this.$route;
      const toPath = _pathToRegexp.default.compile(path);
      try {
        return toPath(params);
      } catch (e) {
        if (e instanceof TypeError) {
          // @HACK warehouseAreaId to :id route
          const p = {
            ...params
          };
          if (this.$route.query.warehouseAreaId) {
            p.id = this.$route.query.warehouseAreaId;
            return toPath(p);
          } else if (this.$route.query.warehouseId) {
            p.id = this.$route.query.warehouseId;
            return toPath(p);
          } else if (e.message === 'Expected "0" to be a string') {
            return toPath(['confirmed']);
          }
        }
        // console.log({ path, params })
        throw e;
      }
    } /* ,
      handleLink(item) {
       const { redirect, path } = item
       if (redirect) {
         this.$router.push(redirect)
         return
       }
       this.$router.push(this.pathCompile(path))
      } */
  }
};
exports.default = _default;