"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.downloadInstantPLSummary = downloadInstantPLSummary;
exports.downloadSupplierStoreByPOSummary = downloadSupplierStoreByPOSummary;
exports.downloadSupplierStoreSummary = downloadSupplierStoreSummary;
exports.downloadSupplierSummary = downloadSupplierSummary;
exports.downloadWarehouseItemSummary = downloadWarehouseItemSummary;
exports.exportFullTimeSalary = exportFullTimeSalary;
exports.exportStoresSalary = exportStoresSalary;
exports.getInstantPL = getInstantPL;
exports.getLaborCost = getLaborCost;
exports.getSaleVSLavourCost = getSaleVSLavourCost;
exports.getStoreCost = getStoreCost;
exports.getStoreStaffDuty = getStoreStaffDuty;
exports.getSupplierByPOStore = getSupplierByPOStore;
exports.getSupplierPOStore = getSupplierPOStore;
exports.getSupplierPOSummary = getSupplierPOSummary;
exports.getSuppliersPO = getSuppliersPO;
exports.getUserLate = getUserLate;
exports.getWarehouseReport = getWarehouseReport;
exports.getfullTimeCost = getfullTimeCost;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getSaleVSLavourCost(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/sale-vs-labour-cost`,
    method: 'get',
    params
  });
}
function getUserLate(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/staff-attendance`,
    method: 'get',
    params
  });
}
function getStoreStaffDuty(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/store-staff-duty`,
    timeout: 12000,
    // request timeout
    method: 'get',
    params
  });
}
function getLaborCost(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/labor-cost`,
    timeout: 12000,
    // request timeout
    method: 'get',
    params
  });
}
function getInstantPL(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/instantPL`,
    timeout: 30000,
    // request timeout
    method: 'get',
    params
  });
}
function getfullTimeCost(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/fullTime-cost`,
    method: 'get',
    params
  });
}
function getStoreCost(params, config = {}) {
  return (0, _request.default)({
    ...config,
    url: `/api/v1/reports/store-cost`,
    method: 'get',
    params
  });
}
function getSuppliersPO(params, config = {}) {
  return (0, _request.default)({
    ...config,
    url: `/api/v1/reports/supplier-po`,
    method: 'get',
    params
  });
}
function getSupplierPOStore(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/supplier-po/store`,
    method: 'get',
    timeout: 12000,
    // request timeout
    params
  });
}
function getSupplierByPOStore(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/supplier-po/store-bypo`,
    method: 'get',
    timeout: 12000,
    // request timeout
    params
  });
}
function getWarehouseReport(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/warehouse-items`,
    timeout: 6500,
    // request timeout
    method: 'get',
    params
  });
}
function downloadSupplierSummary(params) {
  return (0, _request.default)({
    url: `/api/v1/export-data/supplier-summary`,
    method: 'GET',
    params,
    responseType: 'blob'
  });
}
function downloadInstantPLSummary(params) {
  return (0, _request.default)({
    url: `/api/v1/export-data/instantPL-summary`,
    method: 'GET',
    params,
    responseType: 'blob'
  });
}
function downloadSupplierStoreSummary(params) {
  // console.log(params)
  return (0, _request.default)({
    url: `/api/v1/export-data/supplier-store-summary`,
    method: 'GET',
    timeout: 20000,
    // request timeout
    params,
    responseType: 'blob'
  });
}
function downloadWarehouseItemSummary(params) {
  // console.log(params)
  return (0, _request.default)({
    url: `/api/v1/export-data/warehouse-item-summary`,
    method: 'GET',
    timeout: 20000,
    // request timeout
    params,
    responseType: 'blob'
  });
}
function downloadSupplierStoreByPOSummary(params) {
  // console.log(params)
  return (0, _request.default)({
    url: `/api/v1/export-data/supplier-store-bypo`,
    method: 'GET',
    params,
    responseType: 'blob'
  });
}
function exportStoresSalary(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/stores-salary/pdf`,
    method: 'POST',
    responseType: 'blob',
    params
  });
}
function getSupplierPOSummary(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/supplier-po/summary`,
    method: 'POST',
    params
  });
}
function exportFullTimeSalary(params) {
  return (0, _request.default)({
    url: `/api/v1/reports/fulltime-salary/pdf`,
    method: 'POST',
    responseType: 'blob',
    params
  });
}