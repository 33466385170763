"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createExpense = createExpense;
exports.deleteExpense = deleteExpense;
exports.editExpense = editExpense;
exports.getExpense = getExpense;
exports.getExpenses = getExpenses;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getExpenses(params) {
  return (0, _request.default)({
    url: `/api/v1/expenses`,
    method: 'get',
    timeout: 12000,
    // request timeout
    params
  });
}
function getExpense(id) {
  return (0, _request.default)({
    url: `/api/v1/expenses/${id}`,
    method: 'get'
  });
}
function createExpense(data) {
  return (0, _request.default)({
    url: `/api/v1/expenses`,
    data,
    method: 'post'
  });
}
function editExpense(data) {
  return (0, _request.default)({
    url: `/api/v1/expenses/${data.id}`,
    data,
    method: 'put'
  });
}
function deleteExpense(id) {
  return (0, _request.default)({
    url: `/api/v1/expenses/${id}`,
    method: 'delete'
  });
}