"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createCashExpense = createCashExpense;
exports.deleteCashExpense = deleteCashExpense;
exports.editCashExpense = editCashExpense;
exports.getCashExpense = getCashExpense;
exports.getList = getList;
exports.getReport = getReport;
exports.removeExpenseAttachment = removeExpenseAttachment;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses',
    method: 'get',
    params
  });
}
function getCashExpense(id) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses/' + id,
    method: 'get'
  });
}
function createCashExpense(data) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses',
    data,
    method: 'post'
  });
}
function editCashExpense(data) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses/' + data.uuid,
    data,
    method: 'put'
  });
}
function deleteCashExpense(id) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses/' + id,
    method: 'delete'
  });
}
function removeExpenseAttachment(id) {
  return (0, _request.default)({
    url: 'api/v1/cash-expense-attachment/delete/' + id,
    method: 'post'
  });
}
function getReport(params) {
  return (0, _request.default)({
    url: '/api/v1/cash-expenses/report',
    timeout: 5600,
    // request timeout
    method: 'get',
    params
  });
}