"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createApply = createApply;
exports.createLeave = createLeave;
exports.deleteApplyHistory = deleteApplyHistory;
exports.deleteLeave = deleteLeave;
exports.editApply = editApply;
exports.editLeave = editLeave;
exports.editLeaveUsers = editLeaveUsers;
exports.getHistory = getHistory;
exports.getLeave = getLeave;
exports.getLeaveUsers = getLeaveUsers;
exports.getLeaves = getLeaves;
exports.getUploadUrl = getUploadUrl;
exports.getUserLeave = getUserLeave;
exports.getUserLeaves = getUserLeaves;
exports.leaveApprove = leaveApprove;
exports.leaveForStaff = leaveForStaff;
exports.leaveReject = leaveReject;
exports.removeLeaveHistoryAttachment = removeLeaveHistoryAttachment;
exports.setUserLeave = setUserLeave;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function leaveApprove(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-history/approve/' + data.uuid,
    data,
    timeout: 10500,
    // request timeout
    method: 'patch'
  });
}
function leaveReject(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-history/reject/' + data.uuid,
    data,
    method: 'patch'
  });
}
function getLeaveUsers(params) {
  return (0, _request.default)({
    url: '/api/v1/leave-users/',
    method: 'get',
    params
  });
}
function editLeaveUsers(leaveData, selectedUser) {
  return (0, _request.default)({
    url: '/api/v1/leave-users/' + leaveData.uuid,
    data: {
      'selectedUser': selectedUser
    },
    method: 'post'
  });
}

/**
 * for leave-dialog related to quota OT on UserLeave::calQuota()
 * @param params
 * @returns {AxiosPromise}
 */
function getUserLeaves(params) {
  return (0, _request.default)({
    url: '/api/v1/user-leaves/' + params.user_id,
    method: 'get',
    params
  });
}
function getLeaves(params) {
  return (0, _request.default)({
    url: '/api/v1/leave-types',
    method: 'get',
    params
  });
}
function getLeave(id) {
  return (0, _request.default)({
    url: '/api/v1/leave-types/' + id,
    method: 'get'
  });
}
function createLeave(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-types',
    data,
    method: 'post'
  });
}
function editLeave(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-types/' + data.uuid,
    data,
    method: 'patch'
  });
}
function deleteLeave(id) {
  return (0, _request.default)({
    url: '/api/v1/leave-types/' + id,
    method: 'delete'
  });
}
function leaveForStaff(data) {
  return (0, _request.default)({
    url: '/api/v1/leaves/staff/' + data.id,
    data,
    method: 'patch'
  });
}
function setUserLeave(data) {
  return (0, _request.default)({
    url: '/api/v1/user-leaves',
    data,
    method: 'post'
  });
}
function getUserLeave(uuid) {
  return (0, _request.default)({
    url: '/api/v1/user-leaves/' + uuid,
    method: 'get'
  });
}
function createApply(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-history',
    data,
    method: 'post'
  });
}
function editApply(data) {
  return (0, _request.default)({
    url: '/api/v1/leave-history/' + data.uuid,
    data,
    method: 'patch'
  });
}
function getHistory(params) {
  const config = {
    url: '/api/v1/leave-history',
    timeout: 6500,
    method: 'get'
  };
  if (params.signal) {
    config.signal = params.signal;
    delete params.signal;
  }
  config.params = params;
  return (0, _request.default)(config);
}
function deleteApplyHistory(id) {
  return (0, _request.default)({
    url: '/api/v1/leave-history/' + id,
    method: 'delete'
  });
}
function getUploadUrl(fileName) {
  return (0, _request.default)({
    url: `/api/v1/leave-history/get-upload-url`,
    data: {
      fileName
    },
    method: 'post'
  });
}
function removeLeaveHistoryAttachment(id) {
  return (0, _request.default)({
    url: 'api/v1/leave-history-attachment/delete/' + id,
    method: 'post'
  });
}