"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.confirmPurchaseOrder = confirmPurchaseOrder;
exports.createPurchaseOrder = createPurchaseOrder;
exports.deletePurchaseOrder = deletePurchaseOrder;
exports.downloadPurchaseOrder = downloadPurchaseOrder;
exports.downloadRelatedPR = downloadRelatedPR;
exports.editPurchaseOrder = editPurchaseOrder;
exports.generateGCFromPurchaseOrder = generateGCFromPurchaseOrder;
exports.generateGRFromPurchaseOrder = generateGRFromPurchaseOrder;
exports.getList = getList;
exports.getPurchaseOrder = getPurchaseOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getList(params) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders',
    method: 'get',
    timeout: 9000,
    // request timeout
    params
  });
}
function getPurchaseOrder(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + id,
    method: 'get'
  });
}
function createPurchaseOrder(data) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders',
    data,
    method: 'post'
  });
}
function editPurchaseOrder(data) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + data.uuid,
    data,
    method: 'put'
  });
}
function deletePurchaseOrder(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + id,
    method: 'delete'
  });
}
function confirmPurchaseOrder(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + id + '/confirm',
    method: 'post'
  });
}
function downloadPurchaseOrder(id) {
  return (0, _request.default)({
    url: `/api/v1/export-data/purchase-orders/${id}`,
    method: 'GET',
    responseType: 'blob'
  });
}
function downloadRelatedPR(id) {
  return (0, _request.default)({
    url: `/api/v1/export-data/purchase-orders/${id}/related-purchase-requests`,
    method: 'GET',
    responseType: 'blob'
  });
}
function generateGRFromPurchaseOrder(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + id + '/generateGR',
    method: 'post'
  });
}
function generateGCFromPurchaseOrder(id) {
  return (0, _request.default)({
    url: '/api/v1/purchase-orders/' + id + '/generateGC',
    method: 'post'
  });
}