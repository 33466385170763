"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = require("element-ui");
var _moment = _interopRequireDefault(require("moment"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _settings = _interopRequireDefault(require("@/settings"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'App',
  data() {
    return {
      centerDialogVisible: false,
      interruptSWNotif: false
    };
  },
  computed: {
    title() {
      return _settings.default.title || 'Vue Admin Template';
    },
    showDialog: {
      get: function () {
        return this.centerDialogVisible && !this.interruptSWNotif;
      },
      set: function () {
        this.centerDialogVisible = false;
      }
    }
  },
  watch: {
    centerDialogVisible(newVisibility, oldVisibility) {
      if (oldVisibility && !newVisibility) {
        const today = (0, _moment.default)();
        if ('localStorage' in window) {
          localStorage.setItem('installPrompt', today.format('YYYY-MM-DD'));
        } else {
          // use Cookie
          _jsCookie.default.set('installPrompt', today.format('YYYY-MM-DD'), {
            expires: 30,
            path: '/'
          });
        }
      }
    }
  },
  async created() {
    // this.centerDialogVisible = true
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        // add setTimeout if no response Update from user, send messageSW SKIP_WAITING
        /* const autoUpdate = setTimeout(() => {
          this.$workbox.messageSW({ type: 'SKIP_WAITING' })
        }, 25000)*/

        this.interruptSWNotif = true;
        _elementUi.MessageBox.alert('A new version is found. Refresh to load it?', 'New Version', {
          confirmButtonText: 'Update',
          cancelButtonText: 'No, thanks',
          type: 'warning'
        }).then(() => {
          // clearTimeout(autoUpdate)
          this.$workbox.messageSW({
            type: 'SKIP_WAITING'
          });
        }).catch(() => {
          // clearTimeout(autoUpdate)
        }).finally(() => {
          this.interruptSWNotif = false;
        });
      });
      setTimeout(() => {
        const useIsIOS = this.checkForIOS();
        if (!this.interruptSWNotif && useIsIOS && useIsIOS.prompt) {
          this.centerDialogVisible = true;
        }
      }, 600);
    } /*  else if (useIsIOS && useIsIOS.prompt) {
       this.centerDialogVisible = false
      } */
  },

  methods: {
    checkForIOS() {
      if (navigator.standalone) {
        return false;
      }
      let lastPromptSaved = null;
      if ('localStorage' in window) {
        lastPromptSaved = localStorage.getItem('installPrompt');
      } else {
        lastPromptSaved = _jsCookie.default.get('installPrompt');
      }

      // console.log(lastPromptSaved)
      const lastPrompt = (0, _moment.default)(lastPromptSaved === null ? undefined : lastPromptSaved);
      const days = (0, _moment.default)().diff(lastPrompt, 'days');
      const ua = window.navigator.userAgent;
      const webkit = !!ua.match(/WebKit/i);
      const isIPhone = !!ua.match(/iPhone/i);
      let isIPad = !!ua.match(/iPad/i);
      if (webkit && !isIPad) {
        isIPad = ua.match(/AppleWebKit/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
      }
      const isIOS = (isIPad || isIPhone) && !ua.match(/Android/i); // Linux // Mozilla/5.0 (Linux; Android 8.0.0; LG-H930) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Mobile Safari/537.36

      const isSafari = isIOS && webkit && !ua.match(/(CriOS|Android)/i); // Linux // Mozilla/5.0 (Linux; Android 8.0.0; LG-H930) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Mobile Safari/537.36

      const prompt = (isNaN(days) || lastPromptSaved === null || days > 30) && isIOS && isSafari;

      /* if (prompt && 'localStorage' in window) {
        localStorage.setItem('installPrompt', today)
      } */

      if (!prompt && lastPromptSaved !== null && !/\d{4,4}-\d{2,2}-\d{2,2}( \d{1,2}:\d{1,2}:\d{1,2})?/.test(lastPromptSaved)) {
        if ('localStorage' in window) {
          localStorage.setItem('installPrompt', lastPrompt.format('YYYY-MM-DD'));
        } else {
          // use Cookie
          _jsCookie.default.set('installPrompt', lastPrompt.format('YYYY-MM-DD'), {
            expires: 30,
            path: '/'
          });
        }
      }
      return {
        isIOS,
        isSafari,
        prompt
      };
    }
  }
};
exports.default = _default;